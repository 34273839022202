<template>
  <div id="order-view-history">
    <b-overlay :show="quoteLoading">
      <b-card no-body>
        <b-card-body>
          <h4 class="mb-2">
            {{ $t('order.history') }}
          </h4>
          <app-timeline v-if="!quoteLoading && quoteSelected.quote.history">
            <app-timeline-item
              v-for="quoteEvent in quoteSelected.quote.history"
              :key="quoteEvent.id"
              :time="
                new Date(quoteEvent.date).toLocaleDateString($i18n.locale, {
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                })
              "
              :title="quoteEvent.event | enumTranslate('quote_log_event')"
              :variant="resolveQuoteEventVariant(quoteEvent.event)"
            >
            </app-timeline-item>
          </app-timeline>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UIHelper from '@/helpers/ui'

import AppTimeline from '@/components/AppTimeline.vue'
import AppTimelineItem from '@/components/AppTimelineItem.vue'

export default {
  name: 'OrderViewHistory',

  components: {
    AppTimeline,
    AppTimelineItem,
  },

  mixins: [UIHelper],

  props: {
    stateHistory: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'quoteTitle', 'quoteLoading']),
  },
}
</script>
